import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from '../assets/img/Main/ajlogo.png';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/github.svg';
import navIcon3 from '../assets/img/upwork.svg';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center" style={{padding: 20}}>
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" style={{width: 230}} />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/adnanjaferi512/" target="_blank"><img src={navIcon1} alt="Icon" /></a>
              <a href="https://github.com/jaferi512" target="_blank"><img src={navIcon2} alt="Icon" /></a>
              {/* <a href="https://www.upwork.com/freelancers/~01e023530d43f9a1b6" target="_blank"><img src={navIcon3} alt="Icon" /></a> */}
            </div>
            <p>Copyright 2023. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
