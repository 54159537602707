import { Col } from "react-bootstrap"
import images from "../helps"
import iosicon from "../assets/projects/plaforms/ios.svg"
import androidicon from "../assets/projects/plaforms/android.png"

export const ProjectCard = ({ name, logo, ios, android, technology }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={images[logo]} style={{height: 220}} />
        <div className="proj-txtx">
          <h4>{name}</h4>
          <span>{technology}</span>
          <div className="main" style={{flexDirection:'row'}}>
            <div className="left">
            {android && <img className="img-top" onClick={() => window.open(android, "_blank")} src={androidicon} style={{padding:8}} /> }
            </div>
            <div className="right">
            {ios && <img className="img-bottom" onClick={() => window.open(ios, "_blank")} src={iosicon} style={{padding:8}} /> }
            </div>
          </div>
        </div>
      </div>
    </Col>
  )
}
