import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Newsletter } from "./Newsletter";
import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png";
import react from '../assets/stacks/react.png';
import redux from '../assets/stacks/redux.png';
import nodejs from '../assets/stacks/nodejs.png';
import firebase from '../assets/stacks/firebase.png';
import graphql from '../assets/stacks/graphql.png';
import aws from '../assets/stacks/aws.png';
import segment from '../assets/stacks/segments.png';

export const MailchimpForm = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <section className="stack">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="stack-bx wow zoomIn">
                        <h2>My Technology Stack</h2>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme stack-slider">
                            <div className="item">
                                <img src={react} alt="Image" style={{height:70, width: 70}} />
                                <h5>ReactJs/React Native</h5>
                            </div>
                            <div className="item">
                                <img src={redux} alt="Image" style={{height:70, width: 70}} />
                                <h5>redux</h5>
                            </div>
                            <div className="item">
                                <img src={nodejs} alt="Image" style={{height:70, width: 70}} />
                                <h5>NodeJs</h5>
                            </div>
                            <div className="item">
                                <img src={firebase} alt="Image" style={{height:70, width: 70}} />
                                <h5>Firebase</h5>
                            </div>
                            <div className="item">
                                <img src={aws} alt="Image" style={{height:70, width: 70}} />
                                <h5>AWS Web Services</h5>
                            </div>
                            <div className="item">
                                <img src={graphql} alt="Image" style={{height:70, width: 70}} />
                                <h5>GraphQl</h5>
                            </div>
                            <div className="item">
                                <img src={segment} alt="Image" style={{height:70, width: 70}} />
                                <h5>Twillio Segment</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
