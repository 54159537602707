import basscanyon from '../assets/projects/basscanyon.jpg';
import rollingloud from '../assets/projects/rollingloud.jpg';
import sbau from '../assets/projects/sbau.png';
import lostlands from '../assets/projects/lostlands.jpg';
import caliroots from '../assets/projects/caliroots.jpg';
import everwild from '../assets/projects/everwild.jpg';
import eprincipal from '../assets/projects/eprincipal.png';
import reggae from '../assets/projects/reggae.jpg';
import findiss from '../assets/projects/findiss.png';
import listnerkids from '../assets/projects/listenerkids.png';
import s2o from '../assets/projects/s2o.jpg';
import holoholo from '../assets/projects/holoholo.jpg';
import hcff from '../assets/projects/hcff.jpg';
import wrestletalk from '../assets/projects/wrestletalk.png';
import entrenador from '../assets/projects/entrenador.jpg';
import singinglessons from '../assets/projects/singinglessons.png';
import bull from '../assets/projects/bull.png';

//Stacks
import react from '../assets/stacks/react.png';
import redux from '../assets/stacks/redux.png';
import nodejs from '../assets/stacks/nodejs.png';
import firebase from '../assets/stacks/firebase.png';
import graphql from '../assets/stacks/graphql.png';
import aws from '../assets/stacks/aws.png';
import segment from '../assets/stacks/segments.png';

//services
import mobile from '../assets/services/mobile.png';
import uiux from '../assets/services/uiux.png';
import web from '../assets/services/web.png';
import dataentry from '../assets/services/dataentry.png';
import mvp from '../assets/services/mvp.png';
import backend from '../assets/services/backend.png';
const images = {
  basscanyon,
  rollingloud,
  sbau,
  lostlands,
  caliroots,
  everwild,
  eprincipal,
  reggae,
  findiss,
  listnerkids,
  s2o,
  wrestletalk,
  holoholo,
  hcff,
  singinglessons,
  entrenador,
  bull,
  //Stacks
  react,
  redux,
  nodejs,
  firebase,
  graphql,
  aws,
  segment,
  //services
  mobile,
  uiux,
  web,
  dataentry,
  mvp,
  backend,
};
export default images;
