import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCard";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { CONSTDATA } from '../localData/CONSTDATA';

const ExperienceItem = ({ position, company, date }) => (
  <Col md={position === 'Mobile App Team Lead' ? "12" : 6} style={{marginBottom: 10}}>
    <div className="glassy-block">
      <h3>{position}</h3>
      <p>{company}</p>
      <p>{date}</p>
    </div>
  </Col>
);

export const Experiences = () => (
  <section className="experience" id="projects">
    <Container>
      <TrackVisibility>
        {({ isVisible }) => (
          <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
            <h2>EXPERIENCE</h2>
          </div>
        )}
      </TrackVisibility>
      <Row>
        <ExperienceItem
          position="Mobile App Team Lead"
          company="Ropstam Solutions Inc."
          date="June 2023 - Present"
        />
        <ExperienceItem
          position="Sr. React Native Developer"
          company="Ropstam Solutions Inc."
          date="Oct 2021 - June 2023"
        />
        <ExperienceItem
          position="React Native Developer"
          company="Pearl Solutions Pvt Ltd."
          date="Dec 2019 - Oct 2021"
        />
      </Row>
    </Container>
    <img className="background-image-right" src={colorSharp2} alt="background" />
  </section>
);
