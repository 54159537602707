export const CONSTDATA = {
  projects: [
    {
      id: '14',
      name: 'sBAU',
      technology: 'React Native',
      android:
        'https://play.google.com/store/apps/details?id=com.joinbau.bau&hl=en&gl=US',
      ios: 'https://apps.apple.com/au/app/sbau/id1627912809',
      logo: 'sbau',
    },
    {
      id: '15',
      name: 'Singing Lessons',
      technology: 'React Native',
      android:
        'https://play.google.com/store/apps/details?id=app.singinglessons.vocalcoach',
      logo: 'singinglessons',
    },
    {
      id: '1',
      name: 'WrestleTalk',
      technology: 'React Native',
      android: 'https://play.google.com/store/apps/details?id=com.wrestletalk',
      ios: 'https://apps.apple.com/sa/app/wrestletalk/id1630217673',
      logo: 'wrestletalk',
    },
    // {
    //   id: '8',
    //   name: 'S2O Festival',
    //   technology: 'React Native',
    //   android: 'https://play.google.com/store/apps/details?id=com.buzznog.catalyst.s2o',
    //   ios: 'https://apps.apple.com/us/app/s2o/id1500630507',
    //   logo: 's2o',
    // },
    {
      id: '2',
      name: 'Entrenador Vocal - Clases de canto',
      technology: 'React Native',
      android:
        'https://play.google.com/store/apps/details?id=com.vocal_trainer',
      logo: 'entrenador',
    },
    /* {
      id: '3',
      name: 'Lost Lands Festival',
      technology: 'React Native',
      android:
        'https://play.google.com/store/apps/details?id=com.sge.lostlands&hl=en&gl=US',
      ios: 'https://apps.apple.com/us/app/lost-lands-festival-app/id1286799696',
      logo: 'lostlands',
    }, */
    // {
    //   id: '5',
    //   name: 'California Roots Festival',
    //   technology: 'React Native',
    //   android:
    //     'https://play.google.com/store/apps/details?id=com.buzznog.catalyst.californiaroots',
    //   ios: 'https://apps.apple.com/us/app/california-roots/id1498554573',
    //   logo: 'caliroots',
    // },
    // {
    //   id: '6',
    //   name: 'Everwild Music Festival',
    //   technology: 'React Native',
    //   android:
    //     'https://play.google.com/store/apps/details?id=com.buzznog.catalyst.everwild',
    //   ios: 'https://apps.apple.com/us/app/everwild-music-festival/id1636864398',
    //   logo: 'everwild',
    // },
    // {
    //   id: '7',
    //   name: 'Reggae Rise Up',
    //   technology: 'React Native',
    //   android:
    //     'https://play.google.com/store/apps/details?id=com.buzznog.catalyst.reggaeriseupflorida',
    //   ios: 'https://apps.apple.com/us/app/reggae-rise-up/id1589651358',
    //   logo: 'reggae',
    // },
    // {
    //   id: '9',
    //   name: 'Listener Kids',
    //   technology: 'React Native With IAP',
    //   android: 'https://play.google.com/store/apps/details?id=com.listenerkids',
    //   ios: 'https://apps.apple.com/us/app/listener-kids/id1631373640',
    //   logo: 'listnerkids',
    // },
    {
      id: '11',
      name: 'ePrincpal',
      technology: 'React Native',
      android: 'https://play.google.com/store/apps/details?id=com.pearlapperp',
      logo: 'eprincipal',
    },
    // {
    //   id: '12',
    //   name: 'Holo Holo Festival',
    //   technology: 'React Native',
    //   android: 'https://play.google.com/store/apps/details?id=com.buzznog.catalyst.holoholo',
    //   ios: 'https://apps.apple.com/us/app/holo-holo-music-festival/id1672965905',
    //   logo: 'holoholo',
    // },
    // {
    //   id: '13',
    //   name: 'Hill Country Film Festival',
    //   technology: 'React Native',
    //   android:
    //     'https://play.google.com/store/apps/details?id=com.buzznog.catalyst.hillcountryfilmfest',
    //   ios: 'https://apps.apple.com/us/app/hcff/id1499886918',
    //   logo: 'hcff',
    // },
    {
      id: '16',
      name: 'Findiss',
      technology: 'React Native',
      android:
        'https://play.google.com/store/apps/details?id=com.disignir.finddis&hl=en',
      ios: 'https://apps.apple.com/us/app/findiss-usa/id1375383038',
      logo: 'findiss',
    },
    {
      id: '17',
      name: 'Bull - Stocks Price Prediction',
      technology: 'React Native',
      android:
        'https://play.google.com/store/apps/details?id=com.augmen.bullapp',
      logo: 'bull',
    },
  ],
  projects_Featured: [
    {
      id: '3',
      name: 'sBAU',
      technology: 'React Native',
      android:
        'https://play.google.com/store/apps/details?id=com.joinbau.bau&hl=en&gl=US',
      ios: 'https://apps.apple.com/au/app/sbau/id1627912809',
      logo: 'sbau',
    },
    {
      id: '1',
      name: 'WrestleTalk',
      technology: 'React Native',
      android: 'https://play.google.com/store/apps/details?id=com.wrestletalk',
      ios: 'https://apps.apple.com/sa/app/wrestletalk/id1630217673',
      logo: 'wrestletalk',
    },
    // {
    //   id: '8',
    //   name: 'S2O Festival',
    //   technology: 'React Native',
    //   android: 'https://play.google.com/store/apps/details?id=com.buzznog.catalyst.s2o',
    //   ios: 'https://apps.apple.com/us/app/s2o/id1500630507',
    //   logo: 's2o',
    // },
    {
      id: '2',
      name: 'Entrenador Vocal - Clases de canto',
      technology: 'React Native',
      android:
        'https://play.google.com/store/apps/details?id=com.vocal_trainer',
      logo: 'entrenador',
    },
    /* {
      id: '3',
      name: 'Lost Lands Festival',
      technology: 'React Native',
      android:
        'https://play.google.com/store/apps/details?id=com.sge.lostlands&hl=en&gl=US',
      ios: 'https://apps.apple.com/us/app/lost-lands-festival-app/id1286799696',
      logo: 'lostlands',
    }, */
    // {
    //   id: '5',
    //   name: 'California Roots Festival',
    //   technology: 'React Native',
    //   android:
    //     'https://play.google.com/store/apps/details?id=com.buzznog.catalyst.californiaroots',
    //   ios: 'https://apps.apple.com/us/app/california-roots/id1498554573',
    //   logo: 'caliroots',
    // },
    // {
    //   id: '6',
    //   name: 'Everwild Music Festival',
    //   technology: 'React Native',
    //   android:
    //     'https://play.google.com/store/apps/details?id=com.buzznog.catalyst.everwild',
    //   ios: 'https://apps.apple.com/us/app/everwild-music-festival/id1636864398',
    //   logo: 'everwild',
    // },
    // {
    //   id: '7',
    //   name: 'Reggae Rise Up',
    //   technology: 'React Native',
    //   android:
    //     'https://play.google.com/store/apps/details?id=com.buzznog.catalyst.reggaeriseupflorida',
    //   ios: 'https://apps.apple.com/us/app/reggae-rise-up/id1589651358',
    //   logo: 'reggae',
    // },
    // {
    //   id: '9',
    //   name: 'Listener Kids',
    //   technology: 'React Native With IAP',
    //   android: 'https://play.google.com/store/apps/details?id=com.listenerkids',
    //   ios: 'https://apps.apple.com/us/app/listener-kids/id1631373640',
    //   logo: 'listnerkids',
    // },
    {
      id: '11',
      name: 'ePrincpal',
      technology: 'React Native',
      android: 'https://play.google.com/store/apps/details?id=com.pearlapperp',
      logo: 'eprincipal',
    },
    // {
    //   id: '12',
    //   name: 'Holo Holo Festival',
    //   technology: 'React Native',
    //   android: 'https://play.google.com/store/apps/details?id=com.buzznog.catalyst.holoholo',
    //   ios: 'https://apps.apple.com/us/app/holo-holo-music-festival/id1672965905',
    //   logo: 'holoholo',
    // },
    // {
    //   id: '13',
    //   name: 'Hill Country Film Festival',
    //   technology: 'React Native',
    //   android:
    //     'https://play.google.com/store/apps/details?id=com.buzznog.catalyst.hillcountryfilmfest',
    //   ios: 'https://apps.apple.com/us/app/hcff/id1499886918',
    //   logo: 'hcff',
    // }
  ],
  mvp:[
    {
      id: '3',
      name: 'Singing Lessons',
      technology: 'React Native',
      android:
        'https://play.google.com/store/apps/details?id=app.singinglessons.vocalcoach',
      logo: 'singinglessons',
    },
    {
      id: '2',
      name: 'Findiss',
      technology: 'React Native',
      android:
        'https://play.google.com/store/apps/details?id=com.disignir.finddis&hl=en',
      ios: 'https://apps.apple.com/us/app/findiss-usa/id1375383038',
      logo: 'findiss',
    },
    {
      id: '4',
      name: 'Bull - Stocks Price Prediction',
      technology: 'React Native',
      android:
        'https://play.google.com/store/apps/details?id=com.augmen.bullapp',
      logo: 'bull',
    },
  ],
  services: [
    {
      id: '1',
      name: 'Mobile App Development',
      logo: 'mobile',
    },
    {
      id: '2',
      name: 'Website Development',
      logo: 'web',
    },
    {
      id: '3',
      name: 'UI/UX Designing',
      logo: 'uiux',
    },
    {
      id: '4',
      name: 'Complex Backend Systems',
      logo: 'backend',
    },
    {
      id: '5',
      name: 'MVP Development',
      logo: 'mvp',
    },
    {
      id: '6',
      name: 'Data Entry',
      logo: 'dataentry',
    },
  ],
  stacks: [
    {
      id: '1',
      name: 'React & React Native',
      logo: 'react',
    },
    {
      id: '2',
      name: 'Redux',
      logo: 'redux',
    },
    {
      id: '3',
      name: 'Node Js',
      logo: 'nodejs',
    },
    {
      id: '4',
      name: 'Firebase',
      logo: 'firebase',
    },
    {
      id: '5',
      name: 'GraphQL',
      logo: 'graphql',
    },
  ],
};
