import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import {CONSTDATA} from '../localData/CONSTDATA';

export const Education = () => {

  return (
    <section className="experience" id="projects">
      <Container>
      <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>EDUCATION</h2>
              </div>}
            </TrackVisibility>
        <Row>
          <Col size={6}>
          <div class="glassy-block">
          <h3>BS Computer Science</h3>
        <p>GC Univerisity Fsd</p>
        <p>Sept 2015 - Agust 2019</p>
        <p>CGPA = 3.8 / 4.0</p>
      </div>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
